var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("SSH keys"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _vm.fetchingSSHKeys
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              })
            : [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: { loading: _vm.generatingKey, color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.generateSSHKey()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [_vm._v("add")]),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Generate SSH key"),
                        ]),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        label: "Search for a SSH key...",
                        autofocus: "",
                        "hide-details": "",
                        "prepend-inner-icon": "mdi-filter",
                        solo: "",
                        flat: "",
                        "background-color": "grey lighten-4",
                        dense: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { loading: _vm.fetchingSSHKeys, icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$store.dispatch("userStore/fetchSSHKeys")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  attrs: {
                    items: _vm.sshKeys,
                    "item-key": "tknid",
                    search: _vm.search,
                    headers: _vm.headers,
                    options: _vm.tableOptions,
                    "footer-props": {
                      "items-per-page-options": _vm.itemsPerPageOptions,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "top",
                        fn: function ({ pagination, options, updateOptions }) {
                          return [
                            _c("v-data-footer", {
                              staticClass: "no-border",
                              attrs: {
                                pagination: pagination,
                                options: options,
                                itemsPerPageOptions: _vm.itemsPerPageOptions,
                                "items-per-page-text":
                                  "$vuetify.dataTable.itemsPerPageText",
                              },
                              on: { "update:options": updateOptions },
                            }),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                "No SSH keys have been added yet. Click generate SSH key to create a new one."
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `item.ssh_key`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "code",
                              {
                                staticClass:
                                  "text-body-1 d-block text-truncate",
                                style: `width: ${_vm.cellWidth()}`,
                              },
                              [_vm._v(_vm._s(item.ssh_key))]
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _c("copy-to-clipboard", {
                              attrs: {
                                textToCopy: item.ssh_key,
                                buttonClass: "caption font-weight-bold",
                                iconButton: true,
                              },
                            }),
                            _c("SSHDeleteDialog", {
                              attrs: {
                                token: item.ssh_key,
                                dialogTitle: "SSH Key Delete",
                                apiURL: `/tokens/ssh_keys/${item.tknid}`,
                                fetchTokensDispatch: () => {
                                  _vm.$store.dispatch("userStore/fetchSSHKeys")
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }